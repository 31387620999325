import React from 'react'
import styled from 'styled-components'
import '../styles/spectre.css';

import SwitchAmPm from '../InputComponents/SwitchAmPm.js'

const FunctionalComponentNewShipmentDestination = styled.div`
.es-c-container--origin-new-shipment {
  // 🎛
  width: 100%;
  // 🎨
  // 🖋
  font-family: 'Open Sans';
  .wrapper-origin-new-shipment {
    // 🎛
    margin: 0 auto;
    max-width: 1360px;
    // 🎨
    // 📰
    display: grid;
    grid-template-rows: 0.3fr 0.7fr 1fr 0.1fr 0.2fr 1fr;
    grid-template-columns: 1fr;
    grid-template-areas:
    "g_row_1"
    "g_row_2"
    "g_row_3"
    "g_row_4"
    "g_row_5";

    .wrapper-row-1 {
      grid-area: g_row_1; // 🗺
      padding: 9px 15px 9px 0px;
      .es-sg-row-1 {

        .es-sg-title {
          grid-area: sg_title; // 🗺
          font-family: "Teko";
          text-transform: uppercase;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-weight: 400;
          line-height: 1.5;
          font-size: 30px;
          color: #141b1f;
        }
      }
    }

    .wrapper-row-2 {
      grid-area: g_row_2; // 🗺
      border-radius: 4px;
      background-color: #e7edf0;
      padding: 9px 15px 9px 10px;
      border: solid 1px #bbb;
      .es-sg-row-2 {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 0.5fr 0.5fr 0.5fr 0.5fr 1fr;
        grid-template-areas:
        "sg_subtitle sg_subtitle sg_subtitle sg_name sg_name sg_edit";
        .es-sg-subtitle {
          grid-area: sg_subtitle; // 🗺
          font-size: 18px;
          div {
            padding-bottom: 5px;
          }
        }
        .es-sg-name {
          grid-area: sg_name; // 🗺
          font-size: 18px;
          div {
            padding-bottom: 5px;
          }
        }
        .es-sg-edit {
          grid-area: sg_edit; // 🗺
          align-self: start;
          justify-self: end;
        }

      }
    }
    .wrapper-row-3 {
      grid-area: g_row_3; // 🗺
      padding: 9px 15px 9px 10px;
      border: solid 1px #bbb;
      .es-sg-row-3 {
        display: grid;
        grid-template-rows: 1fr;
        grid-column-gap: 20px;
        grid-template-columns: 1fr 0.5fr 0.5fr 0.5fr 0.5fr 1fr;
        grid-template-areas:
        "sg_location_type sg_special_services sg_special_services sg_pickup_number sg_pickup_number sg_default_origin";
        .es-sg-location-type {
          grid-area: sg_location_type; // 🗺
        }
        .es-sg-special-services {
          grid-area: sg_special_services; // 🗺
        }
        .es-sg-pickup-number {
          grid-area: sg_pickup_number; // 🗺
        }
        .es-sg-default-origin {
          grid-area: sg_default_origin; // 🗺
          justify-self: end;
        }

      }
    }
    .wrapper-row-4 {
      grid-area: g_row_4; // 🗺
      padding: 9px 15px 9px 0px;
      .es-sg-row-4 {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 0.5fr 0.5fr 0.5fr 0.5fr 1fr;
        grid-template-areas:
        "sg_blind_origin . . . . .";
        .es-sg-blind-origin {
          grid-area: sg_blind_origin; // 🗺
        }
      }
    }

    .wrapper-row-5 {
      grid-area: g_row_5; // 🗺
      padding: 9px 15px 9px 0px;
      .es-sg-row-5 {
        display: grid;
        grid-template-rows: 1fr;
        grid-column-gap: 15px;
        grid-template-columns: 1fr 0.5fr 0.5fr 0.5fr 0.5fr 1fr;
        grid-template-areas:
        "sg_requested_pickup_date sg_ready_time sg_ampm_1 sg_cutoff_time sg_cutoff_time sg_ampm_2";
        .es-sg-requested-pickup-date {
          grid-area: sg_requested_pickup_date; // 🗺
          max-width: 0.5fr;
        }
        .es-sg-ready-time {
          grid-area: sg_ready_time; // 🗺
        }
        .es-sg-cutoff-time {
          grid-area: sg_cutoff_time; // 🗺
        }
        .es-sg-am-pm {
          grid-area: sg_ampm_1; // 🗺
          align-self: end;
        }
        .es-sg-am-pm-2{
          grid-area: sg_ampm_2; // 🗺
          align-self: end;
          justify-self: start;
        }
      }
    }
  }
}

.es-c-btn.btn-edit {
    color: #666;
    font-weight: 600;
    background-color: transparent;
    border: transparent;
}
.es-c-btn.btn-remove {
    color: #666;
    font-weight: 600;
    background-color: transparent;
    border: transparent;
}

/* .form-group.es-i-ready-time-cst {
  width: 45%;
} */
/* .form-group.es-i-cut-off-time-cst {
  width: 23%;
} */
.form-label.ready-time {
  margin-bottom: .4rem;
}
.form-label.cut-off {
  margin-bottom: .4rem;
}
`;


const EchoShipFunctionalComponent = () => {
  return (

    <FunctionalComponentNewShipmentDestination>
      <div className="es-c-container--origin-new-shipment">
        <div className="wrapper-origin-new-shipment">
          <div className="wrapper-row-1">
            <div className="es-sg-row-1">
              <div className="es-sg-title">
                <div className="es-t-page-title">Destination</div>
              </div>
            </div>
          </div>
          <div className="wrapper-row-2">
            <div className="es-sg-row-2">
              <div className="es-sg-subtitle">
                <div>
                  <strong>Echo - Long Beach</strong>
                </div>
                <div>
                  100 W Broadway,
                </div>

                <div>
                  LONG BEACH, CA 90802
                </div>
              </div>
              <div className="es-sg-name">
              <div>
                <strong>Dave Menzel</strong>
              </div>
              <div>
                562-279-1800
              </div>
              </div>
              <div className="es-sg-edit">
                <button className="es-c-btn btn-edit">REMOVE</button>
                <span
                  style={{
                    color: "#666"
                  }}
                >
                  |
                </span>
                <button className="es-c-btn btn-remove">EDIT</button>
              </div>
            </div>
          </div>
          <div className="wrapper-row-3">
            <div className="es-sg-row-3">
              <div className="es-sg-location-type">
                <div className="form-group es-i-location-type">
                  <label className="form-label" htmlFor="input-example-1">
                    Location Type <span>*</span>
                  </label>
                  <select className="form-select select-lg">
                    <option>Location Type</option>
                    <option>Business</option>
                    <option>Residential</option>
                    <option>Construction Site</option>
                    <option>Trade Show</option>
                  </select>
                </div>
              </div>
              <div className="es-sg-special-services">
                <div className="es-i-special-services">
                  <label
                    className="form-label"
                    htmlFor="input-special-services"
                  >
                    Special Services
                  </label>
                  <div className="form-group">
                    <label>
                      <input type="checkbox" /> Lift-Gate Pickup Required <br />
                    </label>
                    <label>
                      <input type="checkbox" /> Inside Pickup<br />
                    </label>
                    <label>
                      <input type="checkbox" /> Limited Access Fee<br />
                    </label>
                    <label>
                      <input type="checkbox" /> Protect From Freezing<br />
                    </label>
                  </div>
                </div>
              </div>
              <div className="es-sg-pickup-number">
                <div className="form-group es-i-pickup-number">
                  <label
                    className="form-label label-lg"
                    htmlFor="input-pickup-number"
                  >
                    Pickup Number{" "}
                  </label>
                  <input
                    className="form-input input-lg"
                    type="text"
                    id="input-pickup-number"
                    placeholder="Optional"
                  />
                </div>
              </div>
              <div className="es-sg-default-origin">
                <label>
                  <input type="checkbox" /> Set as a default destination
                </label>
              </div>
            </div>
          </div>
          <div className="wrapper-row-4">
            <div className="es-sg-row-4">
              <div className="es-sg-blind-origin">
                <label>
                  <input type="checkbox" /> Add a Blind Origin
                </label>
              </div>
            </div>
          </div>
          <div className="wrapper-row-5">
            <div className="es-sg-row-5">
              <div className="es-sg-requested-pickup-date">
                <div className="form-group es-i-requested-pickup-date">
                  <label
                    className="form-label label-lg"
                    htmlFor="input-requested-pickup-date"
                  >
                    Requested Pickp Date <span>*</span>
                  </label>
                  <input
                    className="form-input input-lg"
                    type="text"
                    id="input-requested-pickup-date"
                  />
                </div>
              </div>
              <div className="es-sg-ready-time">
                <div className="form-group es-i-ready-time-cst">
                  <label className="form-label ready-time" htmlFor="input-state">
                    Ready Time (CST) <span>*</span>
                  </label>
                  <select className="form-select select-lg">
                    <option value>12:00</option>
                    <option value>12:30</option>
                    <option value>1:00</option>
                    <option value>1:30</option>
                    <option value>2:00</option>
                    <option value>2:30</option>
                    <option value>3:00</option>
                    <option value>3:30</option>
                    <option value>4:00</option>
                    <option value>4:30</option>
                    <option value>5:00</option>
                    <option value>5:30</option>
                    <option value>6:00</option>
                    <option value>6:30</option>
                    <option value>7:00</option>
                    <option value>7:30</option>
                    <option value>8:00</option>
                    <option value>8:30</option>
                    <option value>9:00</option>
                    <option value>9:30</option>
                    <option value>10:00</option>
                    <option value>10:30</option>
                    <option value>11:00</option>
                    <option value>11:30</option>
                  </select>
                </div>
              </div>
              <div className="es-sg-am-pm">
                <SwitchAmPm></SwitchAmPm>
              </div>
              <div className="es-sg-cutoff-time">
                <div className="form-group es-i-cut-off-time-cst">
                  <label className="form-label cut-off" htmlFor="input-state">
                    Cut Off Time (CST) <span>*</span>
                  </label>
                  <select className="form-select select-lg">
                    <option value>12:00</option>
                    <option value>12:30</option>
                    <option value>1:00</option>
                    <option value>1:30</option>
                    <option value>2:00</option>
                    <option value>2:30</option>
                    <option value>3:00</option>
                    <option value>3:30</option>
                    <option value>4:00</option>
                    <option value>4:30</option>
                    <option value>5:00</option>
                    <option value>5:30</option>
                    <option value>6:00</option>
                    <option value>6:30</option>
                    <option value>7:00</option>
                    <option value>7:30</option>
                    <option value>8:00</option>
                    <option value>8:30</option>
                    <option value>9:00</option>
                    <option value>9:30</option>
                    <option value>10:00</option>
                    <option value>10:30</option>
                    <option value>11:00</option>
                    <option value>11:30</option>
                  </select>
                </div>
              </div>
              <div className="es-sg-am-pm-2">
                <SwitchAmPm></SwitchAmPm>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FunctionalComponentNewShipmentDestination>
  )
}

export default EchoShipFunctionalComponent;
